/* Built In Imports */
import React, { useState } from 'react';
/* External Imports */
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, List, Text, useBreakpointValue } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

import Slider from 'react-slick';
/* Internal Imports */
/* Components */
import Post from '@lb/components/Blogs/Post';
import SimpleButton from '@lb/components/Buttons/SimpleButton';

/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
/* Services */

/**
 * Returns RelatedContentSlider Component
 *
 * @param sectionContent
 * @param background
 * @param title
 * @param subTitle
 * @param style
 * @param region
 * @param lang
 */

const RelatedContentSlider = ({
  mainSection,
  sectionContent,
  section,
  background,
  title,
  subTitle,
  style,
  region,
  lang,
  pType,
}) => {
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top="191px"
        zIndex="99"
        cursor="pointer"
        border="0"
        w="48px"
        h="48px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        right={{ base: '3px', xl: '-30px' }}
        bgSize="13px"
        onClick={onClick}
        background="#Fff"
        placeItems="center"
        display={{ base: 'none', md: 'grid' }}
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
      >
        <ArrowForwardIcon
          color="#2E2E2E"
          style={{ fontSize: '25px', color: '#2E2E2E', fontWeight: '500' }}
        />
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        display={{ base: 'none', md: 'grid' }}
        top="191px"
        zIndex="10"
        cursor="pointer"
        bgSize="13px"
        background="#fff"
        placeItems="center"
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
        border="0"
        w="48px"
        h="48px"
        textAlign="center"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left={{ base: '3px', xl: '-25px' }}
        onClick={onClick}
      >
        <ArrowBackIcon
          color="#2E2E2E"
          style={{ fontSize: '25px', color: '#2E2E2E', fontWeight: '500' }}
        />
      </Box>
    );
  };
  const [imgIndex, setImgIndex] = useState(0);
  const settingsSmall = {
    dots: false,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    beforeChange: (current, next) => setImgIndex(next),

    slidesToShow: sectionContent?.length < 3 ? 1 : 3,
    slidesToScroll: sectionContent?.length < 3 ? 1 : 3,

    appendDots: dots => (
      <Box mt="20px" className="something" pt="40px">
        <List
          style={{ margin: '0px' }}
          className="dots"
          margin="-11px"
          height="auto"
          minH="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {dots}
        </List>
      </Box>
    ),

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: '20px',

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: sectionContent?.length < 3 ? 1 : 2,
          slidesToScroll: sectionContent?.length < 3 ? 1 : 2,
          // initialSlide: 0,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: sectionContent?.length < 3 ? 1 : 2,
          slidesToScroll: sectionContent?.length < 3 ? 1 : 2,
          arrows: false,
          // initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrow: false,
          slidesToScroll: 1,
          // initialSlide: 0,
        },
      },
    ],
    customPaging: () => (
      <Box
        bg="#9E2828"
        w="16px"
        rounded="full"
        height="16px"
        marginTop="40px"
      ></Box>
    ),
  };

  return (
    <>
      <Box
        py="40px"
        mx="auto"
        display="flex"
        alignItems="center"
        pos="relative"
        overflow="hidden"
        h={pType == 'home' ? '100vh' : 'auto'}
      >
        <Box
          w="100%"
          height="100%"
          pos="absolute"
          top="0"
          bottom="0"
          left={0}
          right={0}
          // zIndex="6"
          _before={{
            content: "''",
            pos: 'absolute',
            bottom: '0',
            width: '25%',
            top: 0,
            right: 0,
            zIndex: useBreakpointValue({ base: 2, md: 5 }),
            background:
              'linear-gradient(270deg, rgba(236, 236, 236, 0.9) 32.72%, rgba(217, 217, 217, 0) 97.29%)',
          }}
          _after={{
            content: "''",
            pos: 'absolute',
            bottom: '0',
            height: '10%',
            left: '0',
            right: 0,
            background:
              'linear-gradient(180deg, #D5CAAE 0%, rgba(255, 255, 255, 0) 100%)',
            backgroundBlendMode: 'multiply',
            transform: 'matrix(1, 0, 0, -1, 0, 0)',
          }}
          margin="0 auto"
          // maxH={{ base: '700px', md: 'auto' }}
          // backgroundImage=""
          // className="RelatedContainer"
        ></Box>
        <Box
          w={{ base: '100%', lg: '100%', xl: '100%' }}
          height="auto"
          // maxH={{ md: '600px', base: '700px' }}
          className="RDSlider"
          position="relative"
          maxW={1200}
          pl={{ base: '', sm: '17px', md: '0' }}
          mx="auto"
          _after={{
            content: "''",
            position: 'absolute',
            width: '340px',
            marginLeft: 'auto',
            height: '100%',
            top: 0,
            bottom: 0,
            right: 0,
            background:
              background ||
              'linear-gradient(270deg, rgba(236, 236, 236, 0.9) 32.72%, rgba(217, 217, 217, 0) 97.29%)',
          }}
        >
          <Box
            mb={{ base: '20px', md: '30px' }}
            ml={{
              base: '15px',
              md: '25px',
              lg: '30px',
              xl: style ? '0' : '42px',
            }}
          >
            <Text
              as="h3"
              fontSize={{ base: '26px', md: '32px' }}
              lineHeight={{ base: '48.9px', md: '63.9px' }}
              color="headingText.main"
              fontWeight={300}
            >
              {title ||
                mainSection?.title ||
                section?.sectionContent[0]?.title ||
                'Explore Devi'}
            </Text>
            <Text
              as="h2"
              fontSize={{ base: '46px', md: '64px' }}
              lineHeight={{ base: '48.9px', md: '63.9px' }}
              fontFamily="FedraSerifDisplay"
              color="headingText.main"
              fontWeight={600}
            >
              {subTitle ||
                mainSection?.titleSubtext ||
                section?.sectionContent[0]?.tabs[0]?.tabTitle ||
                'Related Content'}
            </Text>
          </Box>
          {/* <Slider> */}
          <Box
            pos={{ base: 'relative', md: 'static' }}
            // h={{ base: '357px', md: 'auto' }}
            maxH={{ base: '435px', md: '550px' }}
            overflow={{ base: 'hidden', md: 'unset' }}
            zIndex={{ base: 2, md: 9 }}
            px={{ base: '0', sm: '20px' }}
          >
            <Slider {...settingsSmall} w="100%">
              {sectionContent?.map((card, index) => {
                return (
                  <Box
                    key={nanoid()}
                    position={'relative'}
                    // h={{ base: '525px', md: '394px' }}
                    // h="auto"
                    my="20px"
                    // mr="30px"
                  >
                    <Post
                      height={
                        style === 'homepage-blog-list' && {
                          base: '409px',
                          md: '486px',
                        }
                      }
                      post={card}
                      cardStyle={style}
                      lang={lang}
                      region={region}
                      showShareIcon={imgIndex === index}
                    />
                  </Box>
                );
              })}
            </Slider>
          </Box>
          <Box
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={{ base: '35px', md: '30px' }}
            mb="20px"
            position="relative"
            zIndex={{ base: 6, md: 9 }}
          >
            {mainSection?.button?.length > 0 && (
              <SimpleButton
                style={mainSection?.button[0]}
                region={region}
                lang={lang}
              />
            )}

            {!mainSection?.button?.length && (
              <SimpleButton
                style={{
                  buttonStyle: 'LB-Default',
                  buttonText: 'EXPLORE DEVI',
                  linkUrl: 'blog',
                }}
                region={region}
                lang={lang}
              />
            )}
          </Box>
          <style>
            {`
            @media only screen and (max-width:800px){
                    .RDSlider .slick-list .slick-active {
                      width: 360px !important;
                      margin-right: 16px;
                    }
                    .RDSlider::after{
                      display:none !important;
                      content:"";
                      background:none;
                    }
                    .RDSlider .slick-slider{
              max-height:500px;
            }
                  @media only screen and (max-width:768px){
                    .RDSlider .slick-list .slick-active {
                     width: 340px !important;
                     margin-right: 20px;
                    }
                    .RelatedContainer::before{
                      display:none;
                    }
                  }
                  @media only screen and (max-width:400px){
                    .RDSlider .slick-list .slick-active {
                     width: 300px !important;
                     margin-right: 16px;
                    }
                  }
                  @media only screen and (max-width:350px){
                   .RDSlider .slick-list .slick-active {
                     width: 280px !important;
                    }
                  }
                  .RDSlider .slick-dots li.slick-active div{
                    position:relative;
                  }
                  .RDSlider .slick-dots li.slick-active div::before{
                    content:"";
                    position:absolute;
                    top:-7px;
                    left:-7px;
                    border: 3px solid #FFBC00;
                    width:30px;
                    height:30px;
                    background:transparent;                
                    transform: rotate(180deg);
                    border-radius:100%;
                  } 
            
          `}
          </style>
        </Box>
      </Box>
    </>
  );
};
export default RelatedContentSlider;
